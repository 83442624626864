import React, { useState } from "react"

import { Box, Grid, Button } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import dpcgclogo from "../images/dpcgc/dpcgclogo.svg"
import newicon from "../images/dpcgc/newicon.svg"
import { Link } from "gatsby"
import grbmember5 from "../images/grbmember5.svg"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"

const useStyles = makeStyles(theme => ({
  rooot: {
    width: "100%",
    background: "#243974",
    // height: "22rem",
    padding: "8.5rem 0 2rem 0",
  },
  dpcgclogo: {
    height: "11rem",
  },
  iamailogo: {
    height: "4rem",
    margin: "2rem",
  },
  nav: {
    color: "#243974",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "16px",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      lineHeight: "14px",
    },
  },
  grid: {
    margin: "10px 40px",
    [theme.breakpoints.down("md")]: {
      margin: "0px 10px",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "0px 10px",
    },
  },
  othernav: {
    color: "#4F4F4F",
    fontSize: "14px",
    lineHeight: "16px",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      lineHeight: "14px",
    },
  },
  bigheading: {
    // width:"150px",
    // height:"30px",
    FontFace: "Roboto",
    fontSize: "26px",
    lineHeight: "30px",
    color: "#243974",
    marginleft: "334px",
    padding:"0px 10px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      lineHeight: "30px",
      //   padding: "0 20px 0 15px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px",
      lineHeight: "30px",
      //   padding: "0 20px 0 15px",
    },
  },
  member: {
    height: "15rem",
    marginTop: "25px",
  },
  text: {
    color: "#000000",
    fontSize: "16px",
    lineHeight: "22px",
    fontFamily: "Roboto",
    textAlign: "justify",

    [theme.breakpoints.down("sm")]: {
      // paddingRight: "0 22px",
      fontSize: "14px",
      lineHeight: "22px",
    },
    [theme.breakpoints.down("xs")]: {
      // padding: "0 29px",
      fontSize: "12px",
      lineHeight: "22px",
    },
    [theme.breakpoints.down("md")]: {
      // padding: "0 48px",
      fontSize: "14px",
      lineHeight: "22px",
      // paddingTop: "2px",
    },
  },
  text1: {
    color: " #000000",
    fontSize: "16px",
    lineHeight: "22px",
    fontWeight: "bold",
    fontFamily: "Roboto",

    [theme.breakpoints.down("sm")]: {
      // paddingRight: "0 22px",
      fontSize: "14px",
      lineHeight: "22px",
    },
    [theme.breakpoints.down("xs")]: {
      // padding: "0 29px",
      fontSize: "14px",
      lineHeight: "22px",
    },
    [theme.breakpoints.down("md")]: {
      // padding: "0 48px",
      fontSize: "14px",
      lineHeight: "22px",
      // paddingTop: "44px",
      // paddingRight:"20px",
    },
  },
  leader: {
    height: "20rem",
    borderRadius: "50%",
    [theme.breakpoints.down("sm")]: {
      height: "30rem",
    },
    [theme.breakpoints.down("xs")]: {
      height: "30rem",
    },
    [theme.breakpoints.down("md")]: {
      height: "20rem",
    },
  },
  back: {
    color: "#BDBDBD",
    fontSize: "16px",
    lineHeight: "19px",
    textTransform: "capitalize",
    fontStyle: "normal",
    fontWeight: "normal",
  },
  newicon: {
    height: "16px",
    [theme.breakpoints.down("md")]: {
      height: "14px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "12px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "10px",
    },
  },
}))

export default function GRBMember5() {
  const classes = useStyles()
  return (
    <>
      <Box className={classes.rooot}>
        <Grid container justifyContent="center">
          <Grid item>
            <Link to="/" style={{ textDecoration: "none" }}>
              <img
                src={dpcgclogo}
                alt="dpcgc logo"
                className={classes.dpcgclogo}
              />
            </Link>
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={0} justifyContent="center">
        <Grid item className={classes.grid}>
          <Box height="16px" />
          <Link to="/whoweare" style={{ textDecoration: "none" }}>
            <Typography className={classes.othernav}>ABOUT US</Typography>
          </Link>
        </Grid>
        <Grid item className={classes.grid}>
          <Box height="16px" />
          <Link to="/grbmembers" style={{ textDecoration: "none" }}>
            <Typography className={classes.nav}>GRB MEMBERS</Typography>
          </Link>
        </Grid>
        <Grid item className={classes.grid}>
          <Box height="16px" />
          <Link to="/govtnotification" style={{ textDecoration: "none" }}>
            <Typography className={classes.othernav}>
              GOVT. NOTIFICATIONS
            </Typography>
          </Link>
        </Grid>
        <Grid item className={classes.grid}>
          <Box height="16px" />
          <Link to="/discolsures" style={{ textDecoration: "none" }}>
            <Typography className={classes.othernav}>DISCLOSURES</Typography>
          </Link>
        </Grid>
        <Grid item className={classes.grid}>
          <Box height="16px" />
          <Link to="/faqs" style={{ textDecoration: "none" }}>
            <Typography className={classes.othernav}>FAQS</Typography>
          </Link>
        </Grid>
        <Grid item className={classes.grid}>
          <Box height="16px" />
          <Link to="/leadersspeak" style={{ textDecoration: "none" }}>
            <Typography className={classes.othernav}>LEADERSHIP TEAM</Typography>
          </Link>
        </Grid>
        <Grid item className={classes.grid}>
        <Box height="16px" />
          {/* <Box style={{ textAlign: "right" }}>
          <img src={newicon} alt="new" className={classes.newicon} />
          </Box> */}
          <Link to="/consumerresources" style={{ textDecoration: "none" }}>
            <Typography className={classes.othernav}>
              CONSUMER RESOURCES
            </Typography>
          </Link>
        </Grid>
      </Grid>
      <Box height="8rem" />
      <Grid container justifyContent="center">
        <Grid sm={9} xs={10} md={8} item>
          <Grid container justifyContent="flex-start">
            <Grid item>
              <Link to="/grbmembers" style={{ textDecoration: "none" }}>
                <Button
                  className={classes.back}
                  size="large"
                  // startIcon={<ArrowBackIosIcon />}
                >
                  <ArrowBackIosIcon />
                  Back
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box height="1rem" />

      <Grid container justifyContent="center">
        <Grid item sm={9} md={8} xs={10}>
          <Typography className={classes.bigheading}>GRB Members</Typography>
        </Grid>
        <Grid item sm={9} md={8} xs={10}>
          <Grid container justifyContent="center" style={{padding:"25px 0px"}}>
          <Grid item sm={12} md={4} xs={12} lg={3} style={{padding:"0px 10px"}}>
            {/* <Box height="6rem" /> */}
            <img src={grbmember5} className={classes.leader} />
          </Grid>
          <Grid item sm={12} md={8} xs={12} lg={9} style={{padding:"25px 15px"}}>
            {/* <Box height="6rem" /> */}
            <Typography className={classes.text1}>
              Suhasini Mani Ratnam
            </Typography>
            {/* <Box height="3rem" /> */}
            <Typography className={classes.text}>
              Director, Producer / Head, Organizing Committee Chennai
              International Film Festival
            </Typography>
            <Box height="2rem" />
            <Typography className={classes.text}>
              Suhasini Mani Ratnam is a well-renowned film actor, director, writer
              and producer.
            </Typography>
            <Box height="2rem" />
            <Typography className={classes.text}>
              Beginning from when she was the first ever girl student to graduate
              as a cinematographer from South India, she has had an illustrious
              and inspiring career.
            </Typography>
            <Box height="2rem" />
            <Typography className={classes.text}>
              As an actress, she has acted in more than 350 films in 4 different
              languages. She has been conferred with several honours for her
              performances, ranging from the President's award to many State
              awards; she has also won 7 Filmfare, 8 Cinema Express and 8 Film
              Fans Association Awards.
            </Typography>
            <Box height="2rem" />
            <Typography className={classes.text}>
              Amongst the many notable works to her credit is Vanaprasthanam,
              Theerthadanam, Sindhu Bhairavi, Nanjathai Killade, Ganesh Vasanth,
              Punnagai Panjavaranam and more.
            </Typography>
            <Box height="2rem" />
            <Typography className={classes.text}>
              She has co-written several prominent films such as Roja, Thiruda
              Thiruda, Iruvar, Dil Se Guru and T Raavana. Her feature film Indira,
              the first Indian film to be released in 8 cities in Japan, won her
              the Best Director's Award at the International Film Festival at
              Belarus.
            </Typography>
            <Box height="2rem" />
            <Typography className={classes.text}>
              She has been heading the organizing committee of the Chennai
              International Film Festival since 2010 and continues to do so.
              Suhasini has also been appointed as the Honorary Consul for
              Luxembourg by the President of India in 2014.
            </Typography>
            <Box height="2rem" />
            <Typography className={classes.text}>
              In 2009, she started a trust called NAAM foundation, which works on
              empowering single women in Tamil Nadu; it currently has 200 single
              women from Chennai and 63 from Virudhunagar as beneficiaries.
            </Typography>
          </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box height="10rem" />
    </>
  )
}
